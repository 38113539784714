import React, { useEffect } from "react"
import styles from "./adn-hero-anim.module.scss"
import Anime from "react-anime"
import anime from "animejs"

const AdnHeroAnim = () => {
  let imgDuration = 2000
  let inboundDuration = 1000
  let mid1Duration = 1500
  let mid3Duration = 1000
  let mid5Duration = 600
  let outboundDuration = 900
  let textDuration = 800
  let textDelay = 300

  const dashAnimDuration = 500

  const fadeinOutDuration = 1000
  const dashFadeIn = 0.7
  const lineOpacity = 0.8

  let maintl
  let imgtl
  let dashestl

  const nonDashedLines = [
    "#i1",
    "#i4",
    "#i6",
    "#i8",
    "#i15",
    "#m1-3",
    "#m1-6",
    "#m1-8",
    "#m2-1",
    "#m2-3",
    "#m2-6",
    "#m2-8",
    "#m3-3",
    "#m3-6",
    "#m3-8",
    "#m4-1",
    "#m4-3",
    "#m4-6",
    "#m4-8",
    "#m5-1",
    "#m5-3",
    "#m5-5",
    "#o1-2",
    "#o1-4",
    "#o1-5",
    "#o2-1",
    "#o2-5",
    "#o2-6",
    "#o2-7",
    "#o3-3",
    "#o3-6",
    "#o3-8",
  ]

  const dashedLines = [
    "#i1-dash",
    "#i4-dash",
    "#i6-dash",
    "#i8-dash",
    "#i15-dash",
    "#m1-3-dash",
    "#m1-6-dash",
    "#m1-8-dash",
    "#m2-1-dash",
    "#m2-3-dash",
    "#m2-6-dash",
    "#m2-8-dash",
    "#m3-3-dash",
    "#m3-6-dash",
    "#m3-8-dash",
    "#m4-1-dash",
    "#m4-3-dash",
    "#m4-6-dash",
    "#m4-8-dash",
    "#m5-1-dash",
    "#m5-3-dash",
    "#m5-5-dash",
    "#o1-2-dash",
    "#o1-4-dash",
    "#o1-5-dash",
    "#o2-1-dash",
    "#o2-5-dash",
    "#o2-6-dash",
    "#o2-7-dash",
    "#o3-3-dash",
    "#o3-6-dash",
    "#o3-8-dash",
  ]

  const startDashes = () => {
    dashestl.play()
  }

  useEffect(() => {
    maintl = anime.timeline({
      easing: "easeInOutQuad",
      duration: 2000,
      autoplay: true,
    })

    imgtl = anime.timeline({
      easing: "easeInOutQuad",
      duration: 2000,
      autoplay: true,
    })

    dashestl = anime.timeline({
      easing: "linear",
      duration: dashAnimDuration,
      loop: true,
      autoplay: false,
    })

    maintl
      .add({
        targets: "#in1 line",
        duration: inboundDuration,
        strokeDashoffset: [259, 0],
        opacity: [0, lineOpacity],
      })
      .add({
        targets: "#mid1 path",
        duration: mid1Duration,
        strokeDashoffset: [478, 0],
        opacity: [0, lineOpacity],
      })
      .add(
        {
          targets: "#seattle",
          duration: textDuration,
          opacity: [0, 0.75],
        },
        "-=" + textDelay
      )
      .add(
        {
          targets: "#mid2 path",
          duration: mid1Duration,
          strokeDashoffset: [478, 0],
          opacity: [0, lineOpacity],
        },
        "-=1300"
      )
      .add(
        {
          targets: "#singapore",
          duration: textDuration,
          opacity: [0, 0.75],
        },
        "-=" + textDelay
      )
      .add(
        {
          targets: "#mid3 path",
          duration: mid3Duration,
          strokeDashoffset: [326, 0],
          opacity: [0, lineOpacity],
        },
        "-=" + mid3Duration
      )
      .add(
        {
          targets: "#london",
          duration: textDuration,
          opacity: [0, 0.75],
        },
        "-=" + textDelay
      )
      .add(
        {
          targets: "#mid4 path",
          duration: mid3Duration,
          strokeDashoffset: [326, 0],
          opacity: [0, lineOpacity],
          delay: anime.stagger(20),
        },
        "-=" + (mid3Duration - 100)
      )
      .add(
        {
          targets: "#newyork",
          duration: textDuration,
          opacity: [0, 0.75],
        },
        "-=" + textDelay
      )
      .add(
        {
          targets: "#mid5 line",
          duration: mid5Duration,
          strokeDashoffset: [138, 0],
          opacity: [0, lineOpacity],
          delay: anime.stagger(20),
        },
        "-=" + (mid1Duration - 200)
      )
      .add(
        {
          targets: "#paris",
          duration: textDuration,
          opacity: [0, 0.75],
        },
        "-=" + textDelay
      )
      .add(
        {
          targets: "#out1 line",
          duration: outboundDuration,
          strokeDashoffset: [293, 0],
          opacity: [0, lineOpacity],
          delay: anime.stagger(20),
          complete: startDashes,
        },
        "-=500"
      )
      .add(
        {
          targets: "#out2 line",
          duration: outboundDuration,
          strokeDashoffset: [293, 0],
          opacity: [0, lineOpacity],
          delay: anime.stagger(20),
        },
        "-=1000"
      )
      .add(
        {
          targets: "#out3 line",
          duration: outboundDuration,
          strokeDashoffset: [293, 0],
          opacity: [0, lineOpacity],
          delay: anime.stagger(20),
        },
        "-=800"
      )
      .add(
        {
          targets: nonDashedLines,
          duration: fadeinOutDuration,
          opacity: 0,
          delay: anime.stagger(20),
        },
        "-=800"
      )
      .add(
        {
          targets: dashedLines,
          duration: fadeinOutDuration,
          opacity: dashFadeIn,
          delay: anime.stagger(20),
        },
        "-=1300"
      )

    imgtl.add({
      targets: "#img",
      duration: imgDuration,
      opacity: [0, 1],
    })

    dashestl.add({
      targets: dashedLines,
      strokeDashoffset: [7, 0],
    })
  })

  return (
    <span className={styles.adnanim}>
      <img
        id="img"
        className={styles.adnanim__img}
        src="/img/solutions/adn-hero.png"
        alt="Abstract networking illustration"
      />
      <svg
        className={styles.adnanim__svg}
        width="752px"
        height="730px"
        viewBox="0 0 752 730"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg">
        <defs>
          <rect id="path-1" x="0" y="0" width="752" height="730"></rect>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd">
          <g id="Rectangle-2">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Rectangle"></g>
            <g
              id="Group-4"
              mask="url(#mask-2)"
              strokeLinecap="round"
              strokeLinejoin="round">
              <g transform="translate(460.230277, 365.230277) rotate(-315.000000) translate(-460.230277, -365.230277) translate(169.730277, -269.769723)">
                <g
                  id="mid3"
                  opacity="0.5"
                  transform="translate(126.000000, 444.000000)"
                  strokeDasharray="336">
                  <path
                    d="M24,321.517808 C10.745166,321.517808 0,310.772642 0,297.517808 L0,0"
                    id="m3-1"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M31,314.517808 C17.745166,314.517808 7,303.772642 7,290.517808 L7,0"
                    id="m3-2"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M36,307.517808 C23.8497355,307.517808 14,297.668072 14,285.517808 L14,0"
                    id="m3-3"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M42,300.517808 C30.4020203,300.517808 21,291.115787 21,279.517808 L21,0"
                    id="m3-4"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M47,293.517808 C36.5065898,293.517808 28,285.011218 28,274.517808 L28,0"
                    id="m3-5"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M52,286.517808 C42.6111593,286.517808 35,278.906648 35,269.517808 L35,0"
                    id="m3-6"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M57,279.517808 C48.7157288,279.517808 42,272.802079 42,264.517808 L42,5.68434189e-14"
                    id="m3-7"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M62,271.517808 C54.8202983,271.517808 49,265.697509 49,258.517808 L49,0"
                    id="m3-8"
                    stroke="#FFFFFF"></path>
                </g>
                <g
                  id="mid4"
                  opacity="0.5"
                  transform="translate(417.000000, 605.000000) scale(-1, 1) translate(-417.000000, -605.000000) translate(386.000000, 444.000000)"
                  strokeDasharray="336">
                  <path
                    d="M24,321.517808 C10.745166,321.517808 0,310.772642 0,297.517808 L0,0"
                    id="m4-1"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M31,314.517808 C17.745166,314.517808 7,303.772642 7,290.517808 L7,0"
                    id="m4-2"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M36,307.517808 C23.8497355,307.517808 14,297.668072 14,285.517808 L14,0"
                    id="m4-3"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M42,300.517808 C30.4020203,300.517808 21,291.115787 21,279.517808 L21,0"
                    id="m4-4"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M47,293.517808 C36.5065898,293.517808 28,285.011218 28,274.517808 L28,0"
                    id="m4-5"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M52,286.517808 C42.6111593,286.517808 35,278.906648 35,269.517808 L35,0"
                    id="m4-6"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M57,279.517808 C48.7157288,279.517808 42,272.802079 42,264.517808 L42,5.68434189e-14"
                    id="m4-7"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M62,271.517808 C54.8202983,271.517808 49,265.697509 49,258.517808 L49,0"
                    id="m4-8"
                    stroke="#FFFFFF"></path>
                </g>
                <g
                  id="out1"
                  opacity="0.5"
                  transform="translate(-0.000000, 185.000000)"
                  strokeDasharray="293">
                  <line
                    x1="35.7"
                    y1="290"
                    x2="35.7"
                    y2="0"
                    id="o1-1"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="42.7"
                    y1="290"
                    x2="42.7"
                    y2="0"
                    id="o1-2"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="49.7"
                    y1="290"
                    x2="49.7"
                    y2="0"
                    id="o1-3"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="21.7"
                    y1="290"
                    x2="21.7"
                    y2="0"
                    id="o1-4"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="14.7"
                    y1="290"
                    x2="14.7"
                    y2="0"
                    id="o1-5"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="7.7"
                    y1="290"
                    x2="7.7"
                    y2="0"
                    id="o1-6"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="0.7"
                    y1="290"
                    x2="0.7"
                    y2="0"
                    id="o1-7"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="28.7"
                    y1="290"
                    x2="28.7"
                    y2="0"
                    id="o1-8"
                    stroke="#FFFFFF"></line>
                </g>
                <g
                  id="out2"
                  opacity="0.5"
                  transform="translate(265.800000, 185.000000)"
                  strokeDasharray="291">
                  <line
                    x1="35.7"
                    y1="290"
                    x2="35.7"
                    y2="0"
                    id="o2-1"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="42.7"
                    y1="290"
                    x2="42.7"
                    y2="0"
                    id="o2-2"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="49.7"
                    y1="290"
                    x2="49.7"
                    y2="0"
                    id="o2-3"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="21.7"
                    y1="290"
                    x2="21.7"
                    y2="0"
                    id="o2-4"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="14.7"
                    y1="290"
                    x2="14.7"
                    y2="0"
                    id="o2-5"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="7.7"
                    y1="290"
                    x2="7.7"
                    y2="0"
                    id="o2-6"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="0.7"
                    y1="290"
                    x2="0.7"
                    y2="0"
                    id="o2-7"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="28.7"
                    y1="290"
                    x2="28.7"
                    y2="0"
                    id="o2-8"
                    stroke="#FFFFFF"></line>
                </g>
                <g
                  id="out3"
                  opacity="0.5"
                  transform="translate(530.000000, 185.000000)"
                  strokeDasharray="291">
                  <line
                    x1="7.7"
                    y1="290"
                    x2="7.7"
                    y2="0"
                    id="o3-6"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="0.7"
                    y1="290"
                    x2="0.7"
                    y2="0"
                    id="o3-7"
                    stroke="#FFFFFF"></line>
                </g>
                <g
                  id="mid1"
                  opacity="0.5"
                  transform="translate(-0.000000, 635.000000)"
                  strokeDasharray="478">
                  <path
                    d="M266.01965,219 L266.01965,135.517808 C266.01965,132.756384 263.781074,130.517808 261.01965,130.517808 L24,130.517808 C10.745166,130.517808 3.00449584e-14,119.772642 2.84217094e-14,106.517808 L2.84217094e-14,0 L2.84217094e-14,0"
                    id="m1-1"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M273.01965,219 L273.01965,128.517808 C273.01965,125.756384 270.781074,123.517808 268.01965,123.517808 L31,123.517808 C17.745166,123.517808 7,112.772642 7,99.5178075 L7,0 L7,0"
                    id="m1-2"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M280.01965,219 L280.01965,121.517808 C280.01965,118.756384 277.781074,116.517808 275.01965,116.517808 L36,116.517808 C23.8497355,116.517808 14,106.668072 14,94.5178075 L14,0 L14,0"
                    id="m1-3"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M287.01965,219 L287.01965,114.517808 C287.01965,111.756384 284.781074,109.517808 282.01965,109.517808 L42,109.517808 C30.4020203,109.517808 21,100.115787 21,88.5178075 L21,0 L21,0"
                    id="m1-4"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M294.01965,219 L294.01965,107.517808 C294.01965,104.756384 291.781074,102.517808 289.01965,102.517808 L47,102.517808 C36.5065898,102.517808 28,94.0112178 28,83.5178075 L28,0 L28,0"
                    id="m1-5"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M301.01965,219 L301.01965,100.517808 C301.01965,97.7563838 298.781074,95.5178075 296.01965,95.5178075 L52,95.5178075 C42.6111593,95.5178075 35,87.9066483 35,78.5178075 L35,0 L35,0"
                    id="m1-6"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M308.01965,219 L308.01965,93.5178075 C308.01965,90.7563838 305.781074,88.5178075 303.01965,88.5178075 L57,88.5178075 C48.7157288,88.5178075 42,81.8020788 42,73.5178075 L42,0 L42,0"
                    id="m1-7"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M315.01965,219 L315.01965,85.5178075 C315.01965,82.7563838 312.781074,80.5178075 310.01965,80.5178075 L62,80.5178075 C54.8202983,80.5178075 49,74.6975093 49,67.5178075 L49,0 L49,0"
                    id="m1-8"
                    stroke="#FFFFFF"></path>
                </g>
                <g
                  id="mid2"
                  opacity="0.5"
                  transform="translate(432.500000, 703.000000) scale(-1, 1) translate(-432.500000, -703.000000) translate(284.000000, 635.000000)"
                  strokeDasharray="478">
                  <path
                    d="M266.01965,135.517808 C266.01965,132.756384 263.781074,130.517808 261.01965,130.517808 L24,130.517808 C10.745166,130.517808 2.84217094e-14,119.772642 2.84217094e-14,106.517808 L2.84217094e-14,0 L2.84217094e-14,0"
                    id="m2-1"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M273.01965,128.517808 C273.01965,125.756384 270.781074,123.517808 268.01965,123.517808 L31,123.517808 C17.745166,123.517808 7,112.772642 7,99.5178075 L7,0 L7,0"
                    id="m2-2"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M280.01965,121.517808 C280.01965,118.756384 277.781074,116.517808 275.01965,116.517808 L36,116.517808 C23.8497355,116.517808 14,106.668072 14,94.5178075 L14,0 L14,0"
                    id="m2-3"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M287.01965,114.517808 C287.01965,111.756384 284.781074,109.517808 282.01965,109.517808 L42,109.517808 C30.4020203,109.517808 21,100.115787 21,88.5178075 L21,0 L21,0"
                    id="m2-4"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M289.01965,102.517808 L47,102.517808 C36.5065898,102.517808 28,94.0112178 28,83.5178075 L28,0 L28,0"
                    id="m2-5"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M296.01965,95.5178075 L52,95.5178075 C42.6111593,95.5178075 35,87.9066483 35,78.5178075 L35,0 L35,0"
                    id="m2-6"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M278.195508,88.5178075 L57,88.5178075 C48.7157288,88.5178075 42,81.8020788 42,73.5178075 L42,0"
                    id="m2-7"
                    stroke="#FFFFFF"></path>
                  <path
                    d="M272.336409,80.5178075 L62,80.5178075 C54.8202983,80.5178075 49,74.6975093 49,67.5178075 L49,0"
                    id="m2-8"
                    stroke="#FFFFFF"></path>
                </g>
                <g
                  id="mid5"
                  opacity="0.5"
                  transform="translate(265.800000, 632.000000)"
                  strokeDasharray="138">
                  <line
                    x1="35.287"
                    y1="124.517808"
                    x2="35.287"
                    y2="0"
                    id="m5-1"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="42.245"
                    y1="131.517808"
                    x2="42.245"
                    y2="0"
                    id="m5-2"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="49.203"
                    y1="136.778368"
                    x2="49.203"
                    y2="0"
                    id="m5-3"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="21.371"
                    y1="115"
                    x2="21.371"
                    y2="0"
                    id="m5-4"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="14.413"
                    y1="124.517808"
                    x2="14.413"
                    y2="0"
                    id="m5-5"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="7.455"
                    y1="131.517808"
                    x2="7.455"
                    y2="0"
                    id="m5-6"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="0.497"
                    y1="138"
                    x2="0.497"
                    y2="0"
                    id="m5-7"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="28.329"
                    y1="115"
                    x2="28.329"
                    y2="0"
                    id="m5-8"
                    stroke="#FFFFFF"></line>
                </g>
                <g
                  id="in1"
                  opacity="0.5"
                  transform="translate(291.886370, 1140.902829) rotate(-360.000000) translate(-291.886370, -1140.902829) translate(237.886370, 1011.902829)"
                  strokeDasharray="259">
                  <line
                    x1="31.6136298"
                    y1="257.631579"
                    x2="31.6136298"
                    y2="2.1032065e-12"
                    id="i15"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="106.61363"
                    y1="257.631579"
                    x2="106.61363"
                    y2="9.66338121e-13"
                    id="i1"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="91.6136298"
                    y1="257.631579"
                    x2="91.6136298"
                    y2="1.42108547e-12"
                    id="i2"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="61.6136298"
                    y1="257.631579"
                    x2="61.6136298"
                    y2="3.69482223e-12"
                    id="i3"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="84.6136298"
                    y1="257.631579"
                    x2="84.6136298"
                    y2="3.46744855e-12"
                    id="i4"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="54.6136298"
                    y1="257.631579"
                    x2="54.6136298"
                    y2="5.05906428e-12"
                    id="i5"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="39.6136298"
                    y1="257.631579"
                    x2="39.6136298"
                    y2="2.78532752e-12"
                    id="i6"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="46.6136298"
                    y1="257.631579"
                    x2="46.6136298"
                    y2="2.78532752e-12"
                    id="i7"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="69.6136298"
                    y1="257.631579"
                    x2="69.6136298"
                    y2="2.78532752e-12"
                    id="i8"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="76.6136298"
                    y1="257.631579"
                    x2="76.6136298"
                    y2="2.78532752e-12"
                    id="i9"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="99.6136298"
                    y1="257.631579"
                    x2="99.6136298"
                    y2="2.78532752e-12"
                    id="i10"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="23.6136298"
                    y1="257.631579"
                    x2="23.6136298"
                    y2="3.24007488e-12"
                    id="i11"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="16.6136298"
                    y1="257.631579"
                    x2="16.6136298"
                    y2="3.46744855e-12"
                    id="i12"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="8.61362975"
                    y1="257.631579"
                    x2="8.61362975"
                    y2="3.24007488e-12"
                    id="i13"
                    stroke="#FFFFFF"></line>
                  <line
                    x1="1.44736842"
                    y1="257.631579"
                    x2="1.44736842"
                    y2="2.27373675e-12"
                    id="i14"
                    stroke="#FFFFFF"></line>
                </g>
                <g
                  id="dashes"
                  transform="translate(14.000000, -0.000000)"
                  strokeDasharray="1,3">
                  <line
                    x1="255.5"
                    y1="1269.53441"
                    x2="255.5"
                    y2="1011.90283"
                    id="i15-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="263.5"
                    y1="1269.53441"
                    x2="263.5"
                    y2="1011.90283"
                    id="i6-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="293.5"
                    y1="1269.53441"
                    x2="293.5"
                    y2="1011.90283"
                    id="i8-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="308.5"
                    y1="1269.53441"
                    x2="308.5"
                    y2="1011.90283"
                    id="i4-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="330.5"
                    y1="1269.53441"
                    x2="330.5"
                    y2="1011.90283"
                    id="i1-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <path
                    d="M266.01965,854 L266.01965,756.517808 C266.01965,753.756384 263.781074,751.517808 261.01965,751.517808 L22,751.517808 C9.8497355,751.517808 2.99096877e-14,741.668072 2.84217094e-14,729.517808 L2.84217094e-14,635 L2.84217094e-14,635"
                    id="m1-3-dash"
                    stroke="#FFFFFF"
                    opacity="0"></path>
                  <path
                    d="M287.01965,854 L287.01965,735.517808 C287.01965,732.756384 284.781074,730.517808 282.01965,730.517808 L38,730.517808 C28.6111593,730.517808 21,722.906648 21,713.517808 L21,635 L21,635"
                    id="m1-6-dash"
                    stroke="#FFFFFF"
                    opacity="0"></path>
                  <path
                    d="M301.01965,854 L301.01965,720.517808 C301.01965,717.756384 298.781074,715.517808 296.01965,715.517808 L48,715.517808 C40.8202983,715.517808 35,709.697509 35,702.517808 L35,635 L35,635"
                    id="m1-8-dash"
                    stroke="#FFFFFF"
                    opacity="0"></path>
                  <path
                    d="M567,770.517808 C567,767.756384 564.761424,765.517808 562,765.517808 L324.98035,765.517808 C311.725516,765.517808 300.98035,754.772642 300.98035,741.517808 L300.98035,635 L300.98035,635"
                    id="m2-1-dash"
                    stroke="#FFFFFF"
                    transform="translate(433.990175, 702.758904) scale(-1, 1) translate(-433.990175, -702.758904) "
                    opacity="0"></path>
                  <path
                    d="M553,756.517808 C553,753.756384 550.761424,751.517808 548,751.517808 L308.98035,751.517808 C296.830086,751.517808 286.98035,741.668072 286.98035,729.517808 L286.98035,635 L286.98035,635"
                    id="m2-3-dash"
                    stroke="#FFFFFF"
                    transform="translate(419.990175, 695.758904) scale(-1, 1) translate(-419.990175, -695.758904) "
                    opacity="0"></path>
                  <path
                    d="M532,730.517808 L287.98035,730.517808 C278.591509,730.517808 270.98035,722.906648 270.98035,713.517808 L270.98035,635 L270.98035,635"
                    id="m2-6-dash"
                    stroke="#FFFFFF"
                    transform="translate(401.490175, 682.758904) scale(-1, 1) translate(-401.490175, -682.758904) "
                    opacity="0"></path>
                  <path
                    d="M518.336409,715.517808 L308,715.517808 C300.820298,715.517808 295,709.697509 295,702.517808 L295,635"
                    id="m2-8-dash"
                    stroke="#FFFFFF"
                    transform="translate(406.668205, 675.258904) scale(-1, 1) translate(-406.668205, -675.258904) "
                    opacity="0"></path>
                  <line
                    x1="287.087"
                    y1="756.517808"
                    x2="287.087"
                    y2="632"
                    id="m5-1-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="301.003"
                    y1="768.778368"
                    x2="301.003"
                    y2="632"
                    id="m5-3-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="266.213"
                    y1="756.517808"
                    x2="266.213"
                    y2="632"
                    id="m5-5-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <path
                    d="M148,751.517808 C135.849736,751.517808 126,741.668072 126,729.517808 L126,444"
                    id="m3-3-dash"
                    stroke="#FFFFFF"
                    opacity="0"></path>
                  <path
                    d="M164,730.517808 C154.611159,730.517808 147,722.906648 147,713.517808 L147,444"
                    id="m3-6-dash"
                    stroke="#FFFFFF"
                    opacity="0"></path>
                  <path
                    d="M174,715.517808 C166.820298,715.517808 161,709.697509 161,702.517808 L161,444"
                    id="m3-8-dash"
                    stroke="#FFFFFF"
                    opacity="0"></path>
                  <path
                    d="M434,765.517808 C420.745166,765.517808 410,754.772642 410,741.517808 L410,444"
                    id="m4-1-dash"
                    stroke="#FFFFFF"
                    transform="translate(422.000000, 604.758904) scale(-1, 1) translate(-422.000000, -604.758904) "
                    opacity="0"></path>
                  <path
                    d="M420,751.517808 C407.849736,751.517808 398,741.668072 398,729.517808 L398,444"
                    id="m4-3-dash"
                    stroke="#FFFFFF"
                    transform="translate(409.000000, 597.758904) scale(-1, 1) translate(-409.000000, -597.758904) "
                    opacity="0"></path>
                  <path
                    d="M399,730.517808 C389.611159,730.517808 382,722.906648 382,713.517808 L382,444"
                    id="m4-6-dash"
                    stroke="#FFFFFF"
                    transform="translate(390.500000, 587.258904) scale(-1, 1) translate(-390.500000, -587.258904) "
                    opacity="0"></path>
                  <path
                    d="M385,715.517808 C377.820298,715.517808 372,709.697509 372,702.517808 L372,444"
                    id="m4-8-dash"
                    stroke="#FFFFFF"
                    transform="translate(378.500000, 579.758904) scale(-1, 1) translate(-378.500000, -579.758904) "
                    opacity="0"></path>
                  <line
                    x1="28.7"
                    y1="475"
                    x2="28.7"
                    y2="185"
                    id="o1-2-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="7.7"
                    y1="475"
                    x2="7.7"
                    y2="185"
                    id="o1-4-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="0.7"
                    y1="475"
                    x2="0.7"
                    y2="185"
                    id="o1-5-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="523.7"
                    y1="475"
                    x2="523.7"
                    y2="185"
                    id="o3-6-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="287.5"
                    y1="475"
                    x2="287.5"
                    y2="185"
                    id="o2-1-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="266.5"
                    y1="475"
                    x2="266.5"
                    y2="185"
                    id="o2-5-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="259.5"
                    y1="475"
                    x2="259.5"
                    y2="185"
                    id="o2-6-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                  <line
                    x1="252.5"
                    y1="475"
                    x2="252.5"
                    y2="185"
                    id="o2-7-dash"
                    stroke="#FFFFFF"
                    opacity="0"></line>
                </g>
              </g>
            </g>
            <g
              id="cities"
              mask="url(#mask-2)"
              font-family="DM Sans"
              font-size="15"
              font-weight="normal"
              line-spacing="13">
              <g transform="translate(206.000000, 99.000000)">
                <text
                  id="newyork"
                  transform="translate(238.782486, 28.217514) rotate(-45.000000) translate(-238.782486, -28.217514) ">
                  <tspan x="205.782486" y="36.7175144" fill="#FFFFFF">
                    New York
                  </tspan>
                </text>
                <text
                  id="seattle"
                  transform="translate(21.717514, 63.782486) rotate(-45.000000) translate(-21.717514, -63.782486) ">
                  <tspan x="-1.78248558" y="72.2824856" fill="#FFFFFF">
                    Seattle
                  </tspan>
                </text>
                <text
                  id="london"
                  transform="translate(211.000000, 254.500000) rotate(-45.000000) translate(-211.000000, -254.500000) ">
                  <tspan x="185" y="263" fill="#FFFFFF">
                    London
                  </tspan>
                </text>
                <text
                  id="singapore"
                  transform="translate(388.918525, 448.581475) rotate(-45.000000) translate(-389.918525, -451.581475) ">
                  <tspan x="355.418525" y="460.081475" fill="#FFFFFF">
                    Singapore
                  </tspan>
                </text>
                <text
                  id="paris"
                  transform="translate(442.581475, 208.418525) rotate(-45.000000) translate(-442.581475, -208.418525) ">
                  <tspan x="426.581475" y="216.918525" fill="#FFFFFF">
                    Paris
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </span>
  )
}

export default AdnHeroAnim
