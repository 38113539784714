import styles from "../../../styles/solutions.module.scss"
import cblockStyles from "../../../styles/commons/cblock.module.scss"
import checkStyles from "../../../styles/commons/circlechecks.module.scss"
import React, { useState } from "react"
import Layout from "../../../components/layout/layout"
import StartDemo from "../../../components/start-demo/start-demo"
import AdnHeroAnim from "../../../components/animated-graphs/adn-hero/adn-hero-anim"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../../components/seo"
import withAppContext from "../../../contexts/app.context"
import { ENV_CONSOLE_BASE_URL, ENV_DOCS_BASE_URL } from "../../../utils/secrets"
import TopFeatures from "../../../components/top-features/top-features"
import ComparisonTable from "../../../components/comparison-table/comparison-table"
import CascadeVideo from "../../../components/cascade-video/cascade-video"
import ArrowSvg from "../../../components/svg/arrow"

const AppDeliveryNetworkPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "solutions__app-delivery-network" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const [selectedTopFeatIndex, setselectedTopFeatIndex] = useState(1)

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/solutions/network/app-delivery-network"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__content}>
          <div className="container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1 className={styles.header__title}>
                  Volterra App Delivery Network (ADN)
                </h1>
                <p className={styles.header__description}>
                  A brand new way to deliver modern apps with unparalleled
                  performance and global scale — without any servers, complex
                  infrastructure software or DevOps time
                </p>
                <a
                  aria-label="Start for Free"
                  href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                  className={"btn btn-primary " + styles.header__btn}>
                  Start for Free
                </a>
              </div>
            </div>
          </div>
          <div
            className={`${styles.header__imgwrapper} ${styles.header__imgwrapperFullheight}`}>
            <AdnHeroAnim />
          </div>
        </div>
      </section>

      {/* Benefits of ADN */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Benefits of Volterra ADN
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <TopFeatures
              props={{
                features: [
                  {
                    title: "Dramatically faster applications",
                    desc:
                      "Offloading cloud workloads to our global network of edge PoPs can help you achieve more than 80% reduction in app latency — resulting in a more powerful user experience",
                    imgUrl: "/img/solutions/adn-fast-apps.svg",
                    imgAlt: "Globe with clouds illustration",
                  },
                  {
                    title: "Maximum reliability + security",
                    desc:
                      "Your apps can be automatically deployed across our global network, leveraging built-in app security and intelligent traffic routing around failures to be delivered with maximum uptime and resiliency",
                    imgUrl: "/img/solutions/adn-max-security.svg",
                    imgAlt: "Application interface in a lock illustration",
                  },
                  {
                    title: "Zero infrastructure operations",
                    desc:
                      "Deploying directly to Volterra ADN allows you to focus on your apps, while we manage the K8s control plane, worker nodes, security, DNS and load balancing",
                    imgUrl: "/img/solutions/adn-zero-infra-ops.svg",
                    imgAlt: "Abstract app deployment illustration",
                  },
                  {
                    title: "Very intuitive workflow",
                    desc:
                      "Automate deployment with your favorite GitOps tool or use our intuitive portal to create a workload and configure DNS + load balancer. Your app is available for a global audience within minutes",
                    imgUrl: "/img/solutions/adn-intuituve-workflow.svg",
                    imgAlt: "Cloud workflow illustration",
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Comparison Table */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Volterra ADN vs. Traditional CDN
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <ComparisonTable
              props={{
                cols: ["Features", "Traditional CDN", "Volterra ADN"],
                rows: [
                  {
                    name: "Full featured K8s-based app platform",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Works without an origin server",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Automated deploys & rollbacks",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Gitops & CI/CD tooling",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Multi-layer security",
                    cells: ["Included", "Included"],
                  },
                  {
                    name: "Cache management",
                    cells: ["Required", "Not required"],
                  },
                  {
                    name: "Observability",
                    cells: ["Network", "Network + Apps"],
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Solution Overview */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>Solution Overview</h2>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className={styles.textblock}>
                <p className={cblockStyles.cblock__intro}>
                  Volterra’s global app delivery network (ADN) runs your
                  workloads closer to end users for improved performance and
                  helps you meet emerging compliance requirements. Each Volterra
                  point of presence (PoPs) includes VoltStack and VoltMesh to
                  provide a full-featured app platform that seamlessly
                  integrates with your existing CI/CD tools.
                </p>
                <p className={cblockStyles.cblock__intro}>
                  VoltStack is responsible for operations and security of app
                  clusters, while VoltMesh routes traffic and protects against
                  L4-L7+ attacks. An additional benefit of this distributed app
                  platform is that you can upgrade apps globally without any
                  maintenance windows or worrying about cache invalidations —
                  supporting richer user experiences than a traditional CDN.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <picture>
                <source
                  srcSet="/img/solutions/adn-graph.png"
                  media="(min-width: 576px)"
                />
                <img
                  className="img-fluid"
                  src="/img/solutions/adn-graph.png"
                  alt="App Delivery Network architecture graph"
                />
              </picture>
            </div>
          </div>

          <div className="text-center mt-5 mb-4">
            <h4>Key Capabilities</h4>
          </div>
          <ul className={`${checkStyles.circlechecks} ${styles.keychecks}`}>
            <li className={checkStyles.circlechecks__item}>
              Distributed app orchestration
            </li>
            <li className={checkStyles.circlechecks__item}>
              Zero operations of Kubernets clusters
            </li>
            <li className={checkStyles.circlechecks__item}>
              Distributed service mesh and API GW
            </li>
            <li className={checkStyles.circlechecks__item}>
              Identity, secrets, and key management
            </li>
            <li className={checkStyles.circlechecks__item}>
              DDoS, WAFS, BOT, IPS and API security
            </li>
            <li className={checkStyles.circlechecks__item}>
              Centralized observability and policy
            </li>
          </ul>
        </div>
      </section>

      {/* Ready in minutes */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__bubbles__vmtlmr} ${cblockStyles.cblock__grey}`}>
        <div className={"container"}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Global Production Apps within Minutes
            </h2>
          </div>

          <div className={cblockStyles.cblock__body}>
            <div className={"row " + styles.topfeatsslider}>
              <div className="col-lg-5">
                <div className={styles.topfeatsslider__items}>
                  {/* Item 1 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(1)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 1
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>1</span>
                      <h5>
                        Create Workload <br />
                        Deployment
                        <img
                          className={`ml-2 ${styles.topfeatsslider__caret}`}
                          src="/img/products/caret-icon.svg"
                          alt=">"
                        />
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Simply create a deployment YAML and target to our
                        virtual K8s API server that is globally distributed
                        across the ADN
                      </p>
                      <a
                        href="/docs/how-to/app-management/vk8s-deployment"
                        className={styles.topfeatsslider__link}
                        aria-label="Multi Cloud Networking">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 1 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896698.hd.mp4?s=a9a6077c7e675af3e75f7b60d18d47982edb8f79&profile_id=174",
                                  videoLength: "17000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896655.hd.mp4?s=8b62728dd9e600f977376bac7aab7b02888b7a85&profile_id=174",
                                  videoLength: "27000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 2 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(2)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 2
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>2</span>
                      <h5>Delegate DNS Domain</h5>
                      <img
                        className={styles.topfeatsslider__caret}
                        src="/img/products/caret-icon.svg"
                        alt=">"
                      />
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Delegate a domain or subdomain to automate the creation
                        of public DNS entries and provisioning of TLS
                        certificates
                      </p>
                      <a
                        href="/docs/how-to/app-networking/domain-delegation"
                        className={styles.topfeatsslider__link}
                        aria-label="Multi Cloud Networking">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 2 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896737.hd.mp4?s=74e629f4551e889af9ac8e50ec55021d7311ebc5&profile_id=174",
                                  videoLength: "15000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896697.hd.mp4?s=5ced613dfe5836aa4f9fb58d2a51730680c9eb18&profile_id=174",
                                  videoLength: "27000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 3 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(3)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 3
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>3</span>
                      <h5>
                        Configure Load Balancer & App Security
                        <img
                          className={`ml-2 ${styles.topfeatsslider__caret}`}
                          src="/img/products/caret-icon.svg"
                          alt=">"
                        />
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Configure the internet facing service of your
                        application and enable web application firewall or
                        advanced API security features to control access
                      </p>
                      <a
                        href="/docs/how-to/app-networking/http-load-balancer"
                        className={styles.topfeatsslider__link}
                        aria-label="Multi Cloud Networking">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 3 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896746.hd.mp4?s=80fb48c8fd02eee82601306a73eda8d6fa9ad7c0&profile_id=174",
                                  videoLength: "24000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896740.hd.mp4?s=f5a65abdc8800af0868b526a71a58a0adf5477f3&profile_id=174",
                                  videoLength: "21000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 4 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(4)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 4
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>4</span>
                      <h5>Access your Global App</h5>
                      <img
                        className={styles.topfeatsslider__caret}
                        src="/img/products/caret-icon.svg"
                        alt=">"
                      />
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Send requests to the specified service and let our
                        network route the requests to your workload running in
                        our global ADN
                      </p>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 4 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: "UI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896795.hd.mp4?s=96e1a3ce8db418025f780d3a498c9d720c2ccb16&profile_id=174",
                                  videoLength: "35000",
                                },
                                {
                                  title: "CLI",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896787.hd.mp4?s=ae18a7d829eb1dae5b55c6a5cf420c73732001e9&profile_id=174",
                                  videoLength: "37000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className={styles.topfeatsslider__img}>
                  {selectedTopFeatIndex === 1 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896698.hd.mp4?s=a9a6077c7e675af3e75f7b60d18d47982edb8f79&profile_id=174",
                              videoLength: "17000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896655.hd.mp4?s=8b62728dd9e600f977376bac7aab7b02888b7a85&profile_id=174",
                              videoLength: "27000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 2 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896737.hd.mp4?s=74e629f4551e889af9ac8e50ec55021d7311ebc5&profile_id=174",
                              videoLength: "15000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896697.hd.mp4?s=5ced613dfe5836aa4f9fb58d2a51730680c9eb18&profile_id=174",
                              videoLength: "27000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 3 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896746.hd.mp4?s=80fb48c8fd02eee82601306a73eda8d6fa9ad7c0&profile_id=174",
                              videoLength: "24000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896740.hd.mp4?s=f5a65abdc8800af0868b526a71a58a0adf5477f3&profile_id=174",
                              videoLength: "21000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 4 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: "UI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896795.hd.mp4?s=96e1a3ce8db418025f780d3a498c9d720c2ccb16&profile_id=174",
                              videoLength: "35000",
                            },
                            {
                              title: "CLI",
                              videoUrl:
                                "https://player.vimeo.com/external/449896787.hd.mp4?s=ae18a7d829eb1dae5b55c6a5cf420c73732001e9&profile_id=174",
                              videoLength: "37000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* <FeaturesSlider withNumbers="true" useGif="true">
              <FeaturesSliderContentPane
                title="Deploy VoltMesh"
                desc="Fully automated provisioning of VoltMesh in cloud using the UI or our vesctl CLI - as simple as picking the cloud provider, region, and AZ"
                link="/solutions/multi-cloud/multi-cloud-networking">
              </FeaturesSliderContentPane>
              <FeaturesSliderContentPane
                title="Delegate Domain"
                desc="Delegate a domain or subdomain to manage creation of public DNS entries and automated provisioning of TLS certificates"
                link="/solutions/multi-cloud/multi-cloud-networking">
                <p>This is Delegate Domain content</p>
              </FeaturesSliderContentPane>
              <FeaturesSliderContentPane
                title="Discover & Route Services"
                desc="Configure service discovery of K8s, Consul, or DNS services and implement routing + policy rules to direct incoming requests to discovered services"
                link="/solutions/multi-cloud/multi-cloud-networking">
                <p>This is Discover & Route Services content</p>
              </FeaturesSliderContentPane>
              <FeaturesSliderContentPane
                title="Send Requests"
                desc="Send requests to the specified service or APIs and let VoltMesh handle routing of the requests to the right cluster and endpoint"
                link="/solutions/multi-cloud/multi-cloud-networking">
                <p>This is Send Requests content</p>
              </FeaturesSliderContentPane>
            </FeaturesSlider> */}
          </div>
        </div>
      </section>

      <StartDemo />
    </Layout>
  )
}

export default withAppContext(AppDeliveryNetworkPage)
